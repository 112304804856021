import 'bootstrap';
import './scss/app.scss';
import './scss/main.scss';
import './scss/main-responsive.scss';

require('jquery.easing');
import Swiper from 'swiper';
import Plyr from 'plyr';

$.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};

let debounce = function (func, wait) {
    // we need to save these in the closure
    let timeout, args, context, timestamp;

    return function () {

        // save details of latest call
        context = this;
        args = [].slice.call(arguments, 0);
        timestamp = new Date();

        // this is where the magic happens
        let later = function () {

            // how long ago was the last call
            let last = (new Date()) - timestamp;

            // if the latest call was less that the wait period ago
            // then we reset the timeout to wait for the difference
            if (last < wait) {
                timeout = setTimeout(later, wait - last);

                // or if not we can null out the timer and run the latest
            } else {
                timeout = null;
                func.apply(context, args);
            }
        };

        // we only need to set the timer now if one isn't already running
        if (!timeout) {
            timeout = setTimeout(later, wait);
        }
    }
};

//Duplicate button span for effect
$(function () {
    let btn = $('.btn');
    let btnText;
    let $this = $(this);

    btn.each(function () {
        $this = $(this);

        $this.each(function () {
            btnText = $(this).find('> span').text().split(' ');
        });

        duplicate(function () {
            $this.find('> span').contents().filter(function () {
                return this.nodeType === 3;
            }).remove();
        });
    });

    function duplicate(callback) {
        for (let i = 0; i < btnText.length; i++) {
            let btnSpan;

            btnSpan = $this.find('> span').append('<span />');

            $this.find('> span > span').eq(i).text(btnText[i]);
            $this.find('> span > span').eq(i).attr('data-text', btnText[i]);
        }
        callback();
    }
});

//Transform <img> to "background-image"
$(function () {
    let $item = $('[data-bg="true"]');

    $item.each(function () {
        let $this = $(this);

        $this.parent().css({
            'background-image': 'url("' + $this.attr('src') + '")'
        });
    });
});

//Swiper - Testimonials
$(function () {
    const swiperWrapper = $('.swiper-testimonials');

    if (swiperWrapper.length) {
        const swiper = new Swiper('.swiper-testimonials', {
            init: false,
            speed: 1000,
            effect: 'fade',
            autoplay: {
                delay: 4000
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });

        swiper.on('init', function () {
            swiperWrapper.find('.swiper-navigation-count').text((swiper.realIndex + 1) + ' / ' + swiper.slides.length);
        });

        swiper.on('slideChange', function () {
            swiperWrapper.find('.swiper-navigation-count').text((swiper.realIndex + 1) + ' / ' + swiper.slides.length);
        });

        swiper.init();
    }
});

//Toggle Menu
$(function () {
    let menu = $('.menu'),
        menuToggle = $('.menu-toggler'),
        menuOverlay = $('.menu-overlay'),
        body = $('body');

    function toggleMenu(e) {
        if (!menu.hasClass('menu-open')) {
            menu.addClass('menu-open');
            body.addClass('menu-is-open');

            setTimeout(function () {
                $('.menu-list li').addClass('loaded');
                $('.menu-service').addClass('loaded');
                $('.featured-project').addClass('loaded');
            }, 400);
        } else {
            menu.removeClass('menu-open');
            body.removeClass('menu-is-open');

            $('.menu-list li').removeClass('loaded');
            $('.menu-service').removeClass('loaded');
            $('.featured-project').removeClass('loaded');
        }
    }

    menu.find('.menu-list li').each(function (i) {
        $(this).css({
            transitionDelay: i * 100 + 'ms'
        });
    });

    menu.find('.menu-service').each(function (i) {
        $(this).css({
            transitionDelay: i * 100 + 'ms'
        });
    });

    menuToggle.on('click', toggleMenu);
});

//Navbar scroll
$(function () {
    let header = $('header'),
        body = $('body'),
        w = $(window);

    function toggleNav() {
        if (w.scrollTop() > 165) {
            header.addClass('navbar-scrolled');
        } else {
            header.removeClass('navbar-scrolled');
        }
    }

    w.on('scroll', toggleNav);
});

//Smooth scrolling
$(function () {
    // Select all links with hashes
    $('a[data-smooth="true"]').click(function (event) {
        // On-page links
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            &&
            location.hostname == this.hostname
        ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top - 81
                }, 1000, 'easeInOutExpo', function () {
                    // Callback after animation
                    // Must change focus!
                    var $target = $(target);
                    $target.focus();
                    if ($target.is(":focus")) { // Checking if the target was focused
                        return false;
                    } else {
                        $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                        $target.focus(); // Set focus again
                    }
                });
            }
        }
    });
});

//Form control events
$(function () {
    var formControl = $('.form-control');

    formControl.on('focus', function () {
        $(this).closest('.input').addClass('active');
    });

    formControl.on('blur', function () {
        if ($(this).val().length <= 0) {
            $(this).closest('.input').removeClass('active');
        }
    });

    formControl.on('keypress keydown keyup', function () {
        if ($(this).val().length > 0) {
            $(this).closest('.input').addClass('typing');
        } else {
            $(this).closest('.input').removeClass('typing');
        }
    });
});

//Truncate text
$(function () {
    var fiveElements = $('.truncate');

    function truncateText(item, truncateLength) {
        $.each(item, function () {
            var el = $(this),
                elP = el.find('p'),
                elPText = elP.text(),
                elPTextTrimmed = $.trim(elPText);

            elP.html(elPTextTrimmed.substr(0, truncateLength) + '...');
        });
    }

    truncateText(fiveElements, 220);
});

// Animate when in view
$(function () {
    let element = $('.services-block, .what-we-do, .section-heading h2, .section-heading p, .hero-btn, .hero-title, .hero-subtitle, .fade-in, .big-text p'),
        updatePage,
        animateElements;

    updatePage = function () {
        window.requestAnimationFrame(function () {
            animateElements();
        });
    };

    animateElements = function () {
        $('section').each(function(){
            var that = $(this);

            that.find(element).each(function (i) {
                var $this = $(this);

                if ($this.isInViewport()) {
                    setTimeout(function () {
                        $this.addClass('loaded');
                    }, i * 100);
                }
            });
        });
    };

    setInterval(updatePage, 10);
});

//JS Player
$(function () {
    const players = Plyr.setup('.js-player');

    $('.f-modal-close').on('click', function () {
        if ($('.f-modal').has('.js-player').length > 0) {
            for (var i = 0; i < players.length; i++) {
                players[i].pause();
            }
        }
    });
});